import axios from 'axios';
import * as $ from 'jquery';
import jplayer from 'jplayer';
import * as bootstrap from 'bootstrap';

import '@fortawesome/fontawesome-free';
import '@fortawesome/fontawesome-free/css/all.css';

// let $ = window.$;
let metadataRunning = false;

import trLogo from '../style/svgs/12rnd.svg';
import ubxLogo from '../style/svgs/ubx.svg';

const availableStreamsAPI = {
    url: "https://uapi.gymsystems.co/api/v1/streams",
    method: "GET",
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
    },
};

axios(availableStreamsAPI).then((res) => {
    const { data } = res;

    if (data) {
        data.forEach((stream) => {

            $(".availableStations").append(`
                <div class="artist" data-streamurl="${stream.url}">
                    <div class="artist__thumbnail">
                        <img class="artist__blur" src="${stream.artwork[0]}">
                        <img class="artist__image" src="${stream.artwork[0]}">
                        <div class="artist__ring"></div>
                        <div class="artist__ring artist__ring--outer"></div>
                    </div>
                    <div class="artist__label">
                        <p>${stream.name}</p>
                        <div class="capital">${stream.description}</div>
                    </div>
                </div>
            `);
            
        });

        $(".artist").click(function() {
            playStream($(this).data("streamurl"))
        });

        console.dir(data);
    }

});

if (process.env.THEME === '12rnd') {
    $(".site-logo").attr("src", trLogo);
    $(".site-logo").addClass("tr");
} else {
    $(".site-logo").attr("src", ubxLogo);
}


$(".jp-meta").hide();
$("#jp_container_1").hide();

$("#jquery_jplayer_1").jPlayer({
    cssSelectorAncestor: "#jp_container_1",
    swfPath: "/js",
    supplied: "mp3",
    useStateClassSkin: true,
    autoBlur: false,
    smoothPlayBar: true,
    keyEnabled: true,
    remainingDuration: true,
    autoPlay: true,
    toggleDuration: true
});
function playStream(stream) {

    $("#jquery_jplayer_1").jPlayer("setMedia", {
        title: "Bubble",
        mp3: stream
    });
    $("#jquery_jplayer_1").jPlayer("play", 1);

    $(".loading-spinner").show();
    $(".jp-meta .track-artwork, .jp-meta .track-name").hide();
    $(".jp-meta").css("display", "flex");
    $("#jp_container_1").show();

    $("#jquery_jplayer_1").bind($.jPlayer.event.loadeddata, function(e) {
        $(".loading-spinner").hide();
        $(".jp-meta .track-artwork, .jp-meta .track-name").fadeIn('slow', function() {});
        // console.log(e);
      // $("#playBeganAtTime").text("Play began at time = " + event.jPlayer.status.currentTime);
    });

    if(metadataRunning) {
        clearInterval(metadataRunning)
        metadataRunning = false;
    }

    metadataRunning = setInterval(getMetaData, 5000, stream);
    getMetaData(stream)

}

function getMetaData(stream) {

    const streamMetaData = {
        url: `${stream}/api`.replace(/^http:\/\//i, 'https://'),
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
        },
    };

    axios(streamMetaData).then((res) => {

        let albumArtURL;

        if(res.data) {
            let streamData = res.data;
            if(streamData.currentlyPlaying) {

                console.log(streamData)
                if(streamData.currentlyPlaying.artwork) albumArtURL = streamData.currentlyPlaying.artwork.url;

                // console.log(streamData.currentlyPlaying.track, albumArtURL);
                $(".jp-meta .track-artwork").attr("src", albumArtURL);
                $(".jp-meta .track-name").html(`
                    <strong>${streamData.currentlyPlaying.artistsShort}</strong> <br>
                    ${streamData.currentlyPlaying.track}
                `);

            }
        }
    });
}